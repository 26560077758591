import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-city-selector/1.1.0/index.vue';
// import CheckTravel from '@/component/checkTravel/index.vue'
import consumer_trains_product_checkTheRemainingTrainTickets from '@/lib/data-service/haolv-default/consumer_trains_product_checkTheRemainingTrainTickets'
import consumer_trains_common_queryRailwayTimetable from '@/lib/data-service/haolv-default/consumer_trains_common_queryRailwayTimetable'
import consumer_trains_order_advanceRebooking from '@/lib/data-service/haolv-default/consumer_trains_order_advanceRebooking'

// import trainList from "./../search/1.0.0/api";

export default {
    data () {
        return {
            orderNo: '',
            changeNo: '',
            initStatus: 0,
            pickerOptions: {
                disabledDate: this.disabledDate
            },
            fromStation: {},
            toStation: {},
            params: {
                fromStationCode: '',
                toStationCode: '',
                trainDate: ''
            },
            pickerTrainDate: '',
            oriTrainDate: '',
            isAllTrain: true, // 是否搜索全部类型
            onlySearchTrain: false, // 是否只搜高铁列车
            checkTrainList: [],
            showLoading: false, // loading时是否显示10分钟刷新的提示
            trainList: [],
            daySpacing: '',
            isHasTicket: true, // 是否只显示有票
            reloadTicketFun: '',
            reloadNum: 0,
            loadingNum: 0,

            currentTrainInfo: {}, // 当前选定订票的火车票信息

            fromStationNum: 0,
            toStationNum: 0,
        }
    },
    components: {
        TrainCitySelector,
    },
    created () {},
    mounted () {
        this.orderNo = this.$route.query.orderNo
        if (this.$route.query.changeNo) {
            this.changeNo = this.$route.query.changeNo
        }
        if (this.$route.query.fromStationCode) {
            this.fromStation = {
                stationCode: this.$route.query.fromStationCode
            }
        } else {
            this.fromStation = {}
        }
        if (this.$route.query.toStationCode) {
            this.toStation = {
                stationCode: this.$route.query.toStationCode
            }
        } else {
            this.toStation = {}
        }
        this.params.fromStationCode = this.$route.query.fromStationCode ? this.$route.query.fromStationCode : ''
        this.params.toStationCode = this.$route.query.toStationCode ? this.$route.query.toStationCode : ''
        this.params.trainDate = this.$route.query.time ? this.$route.query.time : this.$moment().locale('zh-cn').format('YYYY-MM-DD')
        this.pickerTrainDate = this.$route.query.time ? this.$route.query.time : this.$moment().locale('zh-cn').format('YYYY-MM-DD')
        let onlySearch = parseInt(this.$route.query.onlySearch) // 是否只搜动车
        if (onlySearch === 1) {
            this.isAllTrain = false
            this.onlySearchTrain = true
            this.checkTrainList = [1, 2] // 传入动车的数值
        }
        this.getTrainList()
    },
    activated () {
    },
    deactivated() {},
    destroyed () {
        clearInterval(this.reloadTicketFun)
    },
    watch: {
        fromStation(newVal, oldVal) {
            if (newVal === null) {
                this.params.fromStationCode = ''
            } else {
                this.params.fromStationCode = newVal.stationCode
            }
            if (this.fromStationNum === 0 && newVal.stationCode !== undefined && newVal.stationName !== undefined) {
                this.oriFromStation = Object.assign({}, newVal)
                this.fromStationNum++
            }
        },
        toStation(newVal, oldVal) {
            if (newVal === null) {
                this.params.toStationCode = ''
            } else {
                this.params.toStationCode = newVal.stationCode
            }
            if (this.toStationNum === 0 && newVal.stationCode !== undefined && newVal.stationName !== undefined) {
                this.oriToStation = Object.assign({}, newVal)
                this.toStationNum++
            }
        },
    },
    computed: {},
    filters: {},
    methods: {
        disabledDate(time) {
            let month = this.$moment().month()
            let day = 0
            if (month === 0 || month === 1) {
                day = 61
            } else if (month === 6) {
                day = 64
            } else {
                day = 63
            }
            return (time.getTime() < Date.now() - 8.64e7) || (time.getTime() > (Date.now() - 8.64e7 + day * 24 * 60 * 60 * 1000))
        },
        setToday() {
            this.pickerTrainDate = this.$moment().locale('zh-cn').format('YYYY-MM-DD')
        },
        changeOnlySearchTrain(val) {
            console.log(val)
            if (val) {
                this.isAllTrain = false
                this.checkTrainList = [1, 2] // 传入动车的数值
            } else {
                this.isAllTrain = true
                this.checkTrainList = []
            }
            // 开始过滤
            this.trainList = this.trainFilter(this.oriTrainList)
        },
        getTrainList() {
            if (this.params.fromStationCode === '' || this.params.fromStationCode === undefined) {
                this.$message({
                    message: '请先选择出发地',
                    type: 'warning'
                })
                return
            }
            if (this.params.toStationCode === '' || this.params.toStationCode === undefined) {
                this.$message({
                    message: '请先选择目的地',
                    type: 'warning'
                })
                return
            }

            if (this.pickerTrainDate === '' || this.pickerTrainDate === null) {
                this.$message({
                    message: '请先选择日期',
                    type: 'warning'
                })
                return
            }
            this.params.trainDate = this.pickerTrainDate
            if (this.params.trainDate === '' || this.params.trainDate === null) {
                this.$message({
                    message: '请先选择日期',
                    type: 'warning'
                })
                return
            }
            let loading
            loading = this.$loading({
                lock: true,
                text: this.showLoading ? '页面停留超过10分钟，正在为您重新查询' : '',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });
            consumer_trains_product_checkTheRemainingTrainTickets(this.params).then(res => {
                this.showLoading = false

                this.oriTrainDate = this.params.trainDate
                let resultList = res.resultList
                this.oriTrainList = resultList
                resultList.forEach(value => {
                    value.timeTable = []
                    value.arriveDateText = this.$moment(value.arriveDate).format('YYYY-MM-DD HH:mm:ss')
                })
                this.trainList = this.trainFilter(this.oriTrainList)
                this.oriFromStation = Object.assign({}, this.fromStation)
                this.oriToStation = Object.assign({}, this.toStation)

                loading.close()
                this.loadingNum++
            }).catch(() => {
                loading.close()
                this.showLoading = false
                this.loadingNum++
            })
        },
        trainFilter(list) {
            let trainList = list
            // 根据时间筛选 如果发车日期在一个月内，则不显示'预约抢'的车次，如果发车日期在一个月外，则不显示'预约和'抢票'
            let startDay = this.$moment()
            let searchDay = this.$moment(this.params.trainDate)
            this.daySpacing = searchDay.diff(startDay, 'days')
            let dayTrainItemList = []
            trainList.forEach(value => {
                if ((this.daySpacing + 1) === 29 || ((this.daySpacing + 1) <= 30 && value.isSale === '1')) { // 预售期内且开售了 || 预售期外且没有开售
                    dayTrainItemList.push(value)
                }
            })
            trainList = dayTrainItemList

            // 筛选列车类型
            if (!this.isAllTrain && this.checkTrainList.length > 0) {
                let trainItemList = []
                this.checkTrainList.forEach(value => {
                    trainList.forEach(val => {
                        if (value === val.motorcycleType) {
                            trainItemList.push(val)
                        }
                    })
                })
                trainList = trainItemList
            }

            // 筛选是否显示有票的
            if (this.isHasTicket) {
                let trainItemList = []
                trainList.forEach(value => {
                    let hasTicket = false
                    value.trainSeatVoList.forEach(val => {
                        console.log(val.seatInventory)
                        if (val.seatInventory > 0) {
                            hasTicket = true
                        }
                    })
                    if (hasTicket) {
                        trainItemList.push(value)
                    }
                })
                trainList = trainItemList
            }
            return trainList
        },
        changeSort() {},
        showThisStationList(index, val) {
            if (val.timeTable.length > 0) {
                return
            }
            let params = {
                fromStationCode: val.fromStationCode,
                toStationCode: val.toStationCode,
                trainCode: val.trainCode,
                trainDate: this.$moment(val.trainStartDate).format('YYYY-MM-DD')
            }
            consumer_trains_common_queryRailwayTimetable(params).then(res => {
                let result = res.results
                let listType = 0
                for (let i = 0, l = result.length; i < l; i++) {
                    if (result[i].stationName === val.fromStationName) {
                        listType = 1
                        result[i].listType = 2
                        continue
                    }
                    if (result[i].stationName === val.toStationName) {
                        listType = 0
                        result[i].listType = 2
                        continue
                    }
                    result[i].listType = listType
                }
                this.trainList[index].timeTable = result
            })
        },
        tableRowClassName({row, rowIndex}) {
            if (row.listType === 0) {
                return 'grey-line'
            } else if (row.listType === 1) {
                return 'default-line'
            } else {
                return 'active-line'
            }
        },
        changeHasTicket(val) {
            if (this.oriTrainList.length === 0) {
                return
            }
            this.trainList = this.trainFilter(this.oriTrainList)
        },
        reserve(val, index, sIndex) {
            console.log(val, index, sIndex)
            this.currentTrainInfo = val
            this.currentTrainInfo.sIndex = sIndex

            let params = {
                changeArriveDate: this.$moment(val.arriveDate).format('YYYY-MM-DD'),
                changeArriveTime: val.toTime,
                changeFromStationCode: val.fromStationCode,
                changeFromStationName: val.fromStationName,
                changeSeatCode: val.trainSeatVoList[sIndex].seatCode,
                changeSeatName: val.trainSeatVoList[sIndex].seatName,
                changeSeatPrice: val.trainSeatVoList[sIndex].seatPrice,
                changeStartDate: this.$moment(val.trainStartDate).format('YYYY-MM-DD'),
                changeStartTime: val.fromTime,
                changeToStationCode: val.toStationCode,
                changeToStationName: val.toStationName,
                changeTrainCode: val.trainCode,
                gmtChangeArrive: this.$moment(val.arriveDate).format('YYYY-MM-DD HH:mm:ss'),
                gmtChangeStart: this.$moment(val.trainStartDate).format('YYYY-MM-DD') + ' ' + val.fromTime + ':00',
                orderNo: this.orderNo,
                changeNo: this.changeNo
            }
            consumer_trains_order_advanceRebooking(params).then(res => {
                this.$router.push({
                    name: 'admin-my-order-train-change',
                    query: {
                        orderNo: this.orderNo,
                        changeNo: res.datas.changeNo
                    }
                })
            })

            /*let result = {
                arriveDate: val.arriveDate,
                daysTrip: val.daysTrip,
                expressFee: val.expressFee,
                fromStationCode: val.fromStationCode,
                fromStationName: val.fromStationName,
                fromTime: val.fromTime,
                sIndex: sIndex,
                serviceCharge: val.serviceCharge,
                spanTime: val.spanTime,
                toStationCode: val.toStationCode,
                toStationName: val.toStationName,
                toTime: val.toTime,
                trainCode: val.trainCode,
                trainNo: val.trainNo,
                trainSeatVoList: JSON.stringify(val.trainSeatVoList),
                trainStartDate: val.trainStartDate,

                orderNo: this.orderNo
            }

            console.log(this.currentTrainInfo)
            this.$router.push({
                name: 'admin-my-order-train-change',
                query: result
            })*/
        },
        reloadTicket() {
            this.reloadTicketFun = setInterval(() => {
                this.reloadNum++
                if (this.reloadNum === 10 * 60) {
                    this.showLoading = true
                    this.getTrainList()
                    // 页面停留超过10分钟，正在为您重新查询
                    this.reloadNum = 0
                }
            }, 1000)
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
